import { Atom, Cmp } from ":mods";
import { TopAnchores, TopExtras } from "./navs";

export const ENDPOINTS = {
  COURSES_LIST: "microsite/our_courses/list/",
  SCHEDULES_LIST: "microsite/course_schedules/list/",
  TEAMS_LIST: "microsite/our_team/list/",
  JOBS_LIST: "microsite/job/list/",
  BLOG_LIST: "microsite/blog/list/",
  BLOG_DETAILS: "microsite/blog/details/",
  CONTACT_US: "microsite/contactus/list/",
  CONTACT_COUNTRIES: "cms/droplist/?list=[ContactUsCountry]",
};
export const COUNTRIES_LIST = [
  { iso2: "SA", name: "Saudi Arabia" },
  { iso2: "AE", name: "United Arab Emirates" },
  { iso2: "KW", name: "Kuwait" },
  { iso2: "EG", name: "Egypt" },
  { iso2: "QA", name: "Qatar" },
  { iso2: "UK", name: "United Kingdom" },
];
export const LOGO = {
  src: "https://i.ibb.co/8rY0dXL/Layer-1.png",
  alt: "logo",
  width: "193px",
  height: "45px",
};

export const ABOUT_DESCRIPTION = [
  {
    id: 1,
    title: "About The Programme",
    description:
      "The programme involves completing tasks that expose you to how different museum professionals work together to conceptualise an exhibition. Whilst completing the tasks you will develop a concept for an exhibition of your own choice. Your exhibition may be selected by the Museums Commission to be part of one of the new Regional Museums that they are developing. Win unique rewards and prizes, whilst learning from leading international experts and gain insight into how they built successful careers within the Industry. The only criteria is an ambition for working within the museum industry in the Kingdom of Saudi Arabia. There is no exam to pass, you will complete a series of challenges that will expose you to what different museums professionals do in their jobs.\n\nThis is a unique opportunity to accelerate your career - Accept the Challenge!",
  },
  {
    id: 2,
    title: "Our Vision",
    description:
      "When creating the programme, we thought hard about how we can best showcase how rewarding a career within the Museum industry can be. We also wanted to showcase how a dedicated team of professionals with diverse skills work together to create world class exhibitions. It was also important for us to emphasise the responsibility that comes with working within a museum; we are the custodians of our culture, the gatekeepers of the stories of our elders and those who will ensure that our rich heritage is preserved for future generations. ",
  },
  {
    id: 3,
    title: "Our Mission",
    description:
      "Our mission is to build an ecosystem of diverse professionals who are all interested in a career within the museum industry. By exposing our participants to a wide range of different specialisms, we hope to empower them to pursue a career that suits their strengths and ambitions. The programme will allow them to determine what trajectory your career can take and provide you with the tools to go out and find a job. All of the work that you do will be output using our groundbreaking e-learning system, into a format that will showcase your newly acquired skills, knowledge and expertise to potential employers.",
  },
];

export const COURSES_LIST = [
  {
    id: 1,
    image: {
      id: 1,
      src: "",
      alt: "",
    },
    title: "Title Of The Course",
    description:
      "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 2,
    image: {
      id: 1,
      src: "",
      alt: "",
    },
    title: "Title Of The Course",
    description:
      "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 3,
    image: {
      id: 1,
      src: "",
      alt: "",
    },
    title: "Title Of The Course",
    description:
      "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 4,
    image: {
      id: 1,
      src: "",
      alt: "",
    },
    title: "Title Of The Course",
    description:
      "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 5,
    image: {
      id: 1,
      src: "",
      alt: "",
    },
    title: "Title Of The Course",
    description:
      "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 6,
    image: {
      id: 1,
      src: "",
      alt: "",
    },
    title: "Title Of The Course",
    description:
      "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 7,
    image: {
      id: 1,
      src: "",
      alt: "",
    },
    title: "Title Of The Course",
    description:
      "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    id: 8,
    image: {
      id: 1,
      src: "",
      alt: "",
    },
    title: "Title Of The Course",
    description:
      "orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];

export const OUR_TEAM_LIST = [
  {
    id: 1,
    title: "Our Speakers",
    members: [
      {
        id: 1,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 2,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 3,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 4,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 5,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 6,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
    ],
  },
  {
    id: 2,
    title: "Our Trainers",
    members: [
      {
        id: 1,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 2,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 3,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 4,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 5,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
      {
        id: 6,
        name: "Name Surname",
        role: "Designation",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
      },
    ],
  },
];

export const JOBS_LIST = [
  {
    id: 1,
    department_name: "Department name",
    place: "Region / Country",
    link: "#",
  },
  {
    id: 2,
    department_name: "Department name",
    place: "Region / Country",
    link: "#",
  },
  {
    id: 3,
    department_name: "Department name",
    place: "Region / Country",
    link: "#",
  },
  {
    id: 4,
    department_name: "Department name",
    place: "Region / Country",
    link: "#",
  },
  {
    id: 5,
    department_name: "Department name",
    place: "Region / Country",
    link: "#",
  },
];
export const HOME_HERO_SECTION = {
  title: "Accelerate Your Museum Career",
  description:
    "Prepare to be inspired, enlightened, and transformed as you accept our challenges. Join our FREE programme to learn from the experts at the V&A Museum and Science Museum Group.",
  buttons: {
    student: {
      theme: "light",
      outlined: false,
      link: "signup/terms-and-conditions",
      text: "Sign Up to Start Learning",
    },
    tutor: {
      theme: "dark",
      outlined: true,
      link: "signup/tutors",
      text: "Join Our Training Team",
    },
  },
};
export const PARTNERS_LIST = [
  {
    src: "",
    alt: "partner",
  },
  {
    src: "",
    alt: "partner",
  },
  {
    src: "",
    alt: "partner",
  },
  {
    src: "",
    alt: "partner",
  },
  {
    src: "",
    alt: "partner",
  },
  {
    src: "",
    alt: "partner",
  },
  {
    src: "",
    alt: "partner",
  },
];

export const QUESTIONS_LIST = [
  {
    id: 1,
    question: "Do I need to be working within the Museum Industry to participate?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquet sapien at?",
  },
  {
    id: 2,
    question: "Do I need to have a degree in Museum related subjects to participate?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquet sapien at?",
  },
  {
    id: 3,
    question: "Can you tell me more about the prizes on offer?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquet sapien at?",
  },
  {
    id: 4,
    question: "What will I get for participating in the programme?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam aliquet sapien at?",
  },
];

export const ROUTES = {
  home: "/",
  about: "/about",
  courses: "/stages",
  teams: "/teams",
  schedules: "/schedules",
  jobs: "/jobs",
  blog: "/blog",
  blog_post: "/blog/:id?",
  contact: "/contact",
};
